var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"Schedule"}},[_c('main',[_c('section',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("Unser Sendeplan")]),_c('div',{staticClass:"week-pagination"},[_vm._m(0),_c('el-date-picker',{attrs:{"format":"[KW] WW - yyyy","name":"weekpicker","clearable":false,"type":"week","picker-options":{
                        firstDayOfWeek: 1,
                    }},on:{"change":function($event){return _vm.onWeekPicked()}},model:{value:(_vm.selectedWeek),callback:function ($$v) {_vm.selectedWeek=$$v},expression:"selectedWeek"}}),_c('el-button-group',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateWeek('decr')}}},[_vm._v(" Letzte Woche ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateWeek()}}},[_vm._v(" Heute ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateWeek('incr')}}},[_vm._v(" Nächste Woche ")])],1)],1)]),_c('section',{staticClass:"sub-container"},[_c('table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isTableLoading),expression:"isTableLoading"}],ref:"schedule-table"},[_c('tr',{staticClass:"days"},[_c('th',{staticClass:"corner"},[_c('span',{staticClass:"year"},[_vm._v(" "+_vm._s(_vm.currYear)+" ")]),_c('span',[_vm._v(" KW "+_vm._s(_vm.currWeek)+" ")])]),_vm._l((_vm.days),function(day,index){return _c('th',{key:index},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(day.date.substr(0, 7)))]),_c('div',[_vm._v(_vm._s(day.weekDay))])])})],2),_vm._l((_vm.times),function(time,timeIndex){return _c('tr',{key:timeIndex,staticClass:"times"},[_c('th',[_vm._v(_vm._s(_vm.getTableTime(timeIndex)))]),_vm._l((_vm.days),function(day,dayIndex){return _c('td',{key:dayIndex,class:{ today: _vm.isCellToday(day.date), now: _vm.isNow(time) }},[(_vm.isStreamerPlanned(day.date, time))?_c('el-tooltip',{attrs:{"open-delay":300,"placement":"bottom"}},[_c('img',{staticClass:"streamer",attrs:{"src":_vm.getImageUrl(
                                        _vm.getCurrStreamer(day.date, time).imageUrl,
                                        'team',
                                    ),"alt":""}}),_c('div',{staticClass:"content",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"streamer"},[(
                                                _vm.getCurrStreamer(day.date, time).modName.length >
                                                    0
                                            )?_c('span',[_vm._v(" "+_vm._s(_vm.getCurrStreamer(day.date, time).modName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getCurrStreamer(day.date, time).name)+" ")])]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.getStreamerTime( _vm.getCurrEventData(day.date, time).time, ))+" ")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"theme"},[_vm._v(" "+_vm._s(_vm.getCurrEventData(day.date, time).theme)+" ")]),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(
                                            _vm.truncateString(
                                                _vm.getCurrEventData(day.date, time).desc,
                                            )
                                        )}}),_c('div',{staticClass:"tags"},_vm._l((_vm.getCurrEventData(
                                                day.date,
                                                time,
                                            ).tags),function(tag,index){return _c('el-tag',{key:index,attrs:{"type":tag.type,"effect":"dark","size":"mini"}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(tag.label)+" ")])])}),1)])])]):_c('el-tooltip',{attrs:{"open-delay":300,"placement":"bottom"}},[_c('img',{attrs:{"src":require("@/assets/images/default-profile.png"),"alt":""}}),_c('div',{staticClass:"content",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("Auto-DJ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.getTableTime(timeIndex)))])])])])],1)})],2)})],2)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{attrs:{"for":"weekpicker"}},[_c('span',[_vm._v(" Kalenderwoche ")]),_c('span',[_vm._v(" auswählen ")])])
}]

export { render, staticRenderFns }